import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useFacialAuth, useVocalAuth } from '../providers';
import { useAuth } from '../providers/AuthProvider';
import { RoutesName } from '../states';

type IProps = {
  facial?: boolean;
  vocal?: boolean;
};

export const ProtectedRoute = ({ facial = false, vocal = false }: IProps) => {
  const { logged } = useAuth();
  const { facialValidated } = useFacialAuth();
  const { vocalValidated } = useVocalAuth();

  const canEnter = useMemo(
    () =>
      facial || vocal ? (facialValidated || vocalValidated) && logged : logged,
    [facial, vocal, facialValidated, vocalValidated, logged]
  );

  return canEnter ? (
    <Outlet />
  ) : (
    <Navigate to={facial ? RoutesName.noAuthorized : RoutesName.login} />
  );
};
