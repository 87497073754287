import { OperationResultReason, OperationStatus, VoiceResultReason, endPoints, family } from '../states';
import { RequestMethods, useLogger, useRequest, useVariables } from '../hooks';
import { useAuth } from '../providers';
import { useTracking } from '@facephi/sdk-web';


export function useFinishOperation() {
const { apiDemoUrl } = useVariables();
const { request: demoRequest } = useRequest(apiDemoUrl);
const { demoVersion } = useAuth();
const { operationId } = useTracking();
const {
    captureException,
    captureMessage,
  } = useLogger();

const sendFinalOperationStatus = async (status: OperationStatus, reason: OperationResultReason | VoiceResultReason | null) => {
    try {
        await demoRequest(endPoints.Finish, {
          method: RequestMethods.post,
          headers: {
            'x-demo-app-version': demoVersion,
            'x-inphinite-operationid': operationId,
          },
          data: {
            status,
            family,
            reason,
            hasVideo: false,
          },
        });
      } catch (e: any) {
        captureMessage(
          `Error in POST to ${endPoints.Finish} with status: ${status} and family Authentication`
        );
        captureException(e, { operation: 'send Operation Status', operationId, status, reason});
      }
    }
    return { sendFinalOperationStatus };
};
