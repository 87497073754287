import { Dispatch, useEffect } from 'react';
import {
  FaceCapture,
  useTracking,
  TrackingSteps,
  TrackingStatus,
  AssetsType,
  SelphiExtractionFinishEvent,
} from '@facephi/sdk-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 } from 'uuid';
import { useLogger, useFinishOperation } from '../../hooks';
import { useFacialAuth, useAuth, useGlobal } from '../../providers';
import {
  OperationResultReason,
  RoutesName,
  registrationState,
  OperationStatus,
  antispoofingState,
} from '../../states';

type FaceWidgetProps = {
  setLoading: Dispatch<boolean>;
  transfer: boolean;
};

export const FaceWidget = ({ setLoading, transfer }: FaceWidgetProps) => {
  const { i18n } = useTranslation();
  const { email, startTracking, setStartTracking } = useAuth();
  const [registration, setRegistration] = useRecoilState(registrationState);
  const antispoofing = useRecoilValue(antispoofingState);

  const navigate = useNavigate();
  const {
    trackingStart,
    trackingStatus,
    trackingAsset,
    operationId,
    changeOperationId,
    generateOperationId,
  } = useTracking();
  const { setLocation, setSession } = useGlobal();

  const {
    onSaveFacial,
    onValidateSelphi,
    facialValidated,
    face,
    onValidateFace,
    error,
    onValidateSpoof,
  } = useFacialAuth();

  const { captureMessage } = useLogger();
  const { sendFinalOperationStatus } = useFinishOperation();
  let timeoutId: NodeJS.Timeout;

  useEffect(() => {
    setLocation('facialWidget');
    if (process.env.REACT_APP_TEST && transfer) {
      setTimeout(() => {
        onExtractionFinish({
          detail: { extractionData: { bestImage: { data: face } } },
        } as SelphiExtractionFinishEvent);
      }, 3000);
    }

    if (!facialValidated) {
      generateOperationId();
    }

    if (startTracking) {
      trackingStart(
        [TrackingSteps.start, TrackingSteps.selphiWidget, TrackingSteps.finish],
        email,
        v4()
      );
      captureMessage('Face Process started');
    }
  }, []);

  useEffect(() => {
    if (operationId) setSession(operationId);
  }, [operationId]);

  useEffect(() => {
    if (error) {
      captureMessage(`Tracking status denied: ${error}`);
      trackingStatus(
        TrackingStatus.denied,
        error,
        v4(),
        TrackingSteps.selphiWidget
      );
    }
  }, [error]);

  useEffect(() => {
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, []);

  const onExtractionFinish = async (event: SelphiExtractionFinishEvent) => {
    setLoading(true);

    try {
      if ((transfer && facialValidated) || !registration) {
        // Facial matching
        trackingAsset(
          event?.detail?.extractionData?.bestImage?.data as string,
          AssetsType.selfie,
          v4(),
          TrackingSteps.selphiWidget
        );
        const response = await onValidateSelphi(
          event?.detail?.extractionData?.bestImage?.data as string
        );
        let result = false;

        if (response) {
          result = true;
          if (antispoofing) {
            const responseAntispoof = await onValidateSpoof(
              event.detail?.extractionData?.encryptedLivenessRaw as Blob | File
            );

            result = responseAntispoof;
          }
        }
        if (result) {
          timeoutId = setTimeout(() => {
            trackingStatus(
              TrackingStatus.succeeded,
              '',
              v4(),
              TrackingSteps.selphiWidget
            );
            sendFinalOperationStatus(OperationStatus.SUCCEEDED, null);
          }, 3000);
          setStartTracking(false);
          navigate(RoutesName.transferSuccessful);
        } else {
          sendFinalOperationStatus(
            OperationStatus.DENIED,
            OperationResultReason.facialAuthenticationNotPassed
          );
          navigate(RoutesName.transferDenied);
        }
        changeOperationId('');
      } else {
        // Facial registration
        const response = await onValidateFace(
          event?.detail?.extractionData?.bestImage?.data as string
        );

        if (response) {
          onSaveFacial(
            event?.detail?.extractionData?.bestImage?.data as string
          );
          setRegistration(false);
          setStartTracking(false);
          navigate(RoutesName.successfulRegister);
        } else {
          navigate(RoutesName.deniedRegister);
        }
        changeOperationId('');
      }
    } catch (error) {
      if (transfer) {
        sendFinalOperationStatus(
          OperationStatus.DENIED,
          OperationResultReason.selphiInternalError
        );
      }
      navigate(RoutesName.deniedRegister);
    }
  };

  const onExtractionTimeout = () => {
    captureMessage('Operations denied: Timeout');
    setStartTracking(true);
    changeOperationId('');
    if (transfer) {
      trackingStatus(
        TrackingStatus.denied,
        OperationResultReason.selphiTimeout,
        v4(),
        TrackingSteps.selphiWidget
      );
      sendFinalOperationStatus(
        OperationStatus.DENIED,
        OperationResultReason.selphiTimeout
      );
    }
  };

  const onUserCancel = () => {
    captureMessage('Denied register: User cancel');
    setStartTracking(true);
    changeOperationId('');
    if (transfer) {
      trackingStatus(
        TrackingStatus.cancelled,
        OperationResultReason.selphiCancelByUser,
        v4(),
        TrackingSteps.selphiWidget
      );
      sendFinalOperationStatus(
        OperationStatus.CANCELLED,
        OperationResultReason.selphiCancelByUser
      );
    }
    navigate(RoutesName.deniedRegister);
  };

  const onTimeoutFinish = () => {
    navigate(RoutesName.home);
  };

  return (
    <FaceCapture
      className="sdk-widget selphi-widget"
      selphiWidgetSettings={{
        language: i18n.language.split('-')[0],
        disableTutorial: true,
        interactible: false,
        cropFactor: 1.7,
        antispoof: antispoofing,
        previewImage: false,
      }}
      onExtractionFinish={onExtractionFinish}
      onExtractionTimeout={onExtractionTimeout}
      onUserCancel={onUserCancel}
      onTimeoutButtonClick={onTimeoutFinish}
    />
  );
};
